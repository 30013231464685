import React from 'react';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import './App.scss';
const LandingPage = React.lazy(() => import('./component/landingPage/landingPage'));
const InvitationPage = React.lazy(() => import('./component/invitationPage/invitationPage'));
const FaqsPage = React.lazy(() => import('./component/faqsPage/faqsPage'));
const GalleryPage = React.lazy(() => import('./component/galleryPage/galleryPage'));
const OurStoryPage = React.lazy(() => import('./component/ourStoryPage/ourStoryPage'));
const RSVPListPage = React.lazy(() => import('./component/rsvpListPage/rsvpListPage'));

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <React.Suspense fallback={<>...</>}>
              <LandingPage />
            </React.Suspense>
          } />
          <Route path="/invitation" element={
            <React.Suspense fallback={<>...</>}>
              <InvitationPage />
            </React.Suspense>
          } />
          <Route path="/faqs" element={
            <React.Suspense fallback={<>...</>}>
              <FaqsPage />
            </React.Suspense>
          } />
          <Route path="/gallery" element={
            <React.Suspense fallback={<>...</>}>
              <GalleryPage />
            </React.Suspense>
          } />
          <Route path="/story" element={
            <React.Suspense fallback={<>...</>}>
              <OurStoryPage />
            </React.Suspense>
          } />
          <Route path="/rsvp/list" element={
            <React.Suspense fallback={<>...</>}>
              <RSVPListPage />
            </React.Suspense>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
